<template>
  <div id="app">
    <header class="header">
      <div class="logo">
        <img src="../assets/images/logo.png" alt="" />
      </div>
      <div class="et-hero-tabs-container">
        <a class="et-hero-tab" href="#tab-bnaner">首页</a>
        <a class="et-hero-tab" href="#tab-intr">产品介绍</a>
        <a class="et-hero-tab" href="#tab-cooperation">合作品牌</a>
        <a class="et-hero-tab" href="#tab-supplier">供应商入驻</a>
        <a class="et-hero-tab" href="#tab-about">关于我们</a>
        <span class="et-hero-tab-slider"></span>
        <p>联系客服：0571-8854-7788</p>
      </div>
    
    </header>
    <main>
      <section class="bnaner et-slide" id="tab-bnaner">
        <img src="../assets/images/banner.png" alt="" />
      </section>
      <section class="side et-slide" id="tab-side">
        <div class="title">一站式商品采购</div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="加气砖系列" name="first">
            <div>
              <img
                src="../assets/images/u=2268542685,3146608953&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto.webp"
                alt=""
              />蒸压加气混凝土砂加气 B03级
            </div>
            <div>
              <img
                src="../assets/images/u=2741025976,2928865828&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto.webp"
                alt=""
              />蒸压加气混凝土 B03级
            </div>
            <div>
              <img
                src="../assets/images/u=36529644,1190754266&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto.webp"
                alt=""
              />蒸压加气混凝土 B03级
            </div>
            <div>
              <img
                src="../assets/images/u=804385356,2988014069&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto.webp"
                alt=""
              />蒸压加气混凝土 B03级
            </div>
          </el-tab-pane>
          <el-tab-pane label="原材/耗材系列" name="second">
            <div>
              <img
                src="../assets/images/u=2268542685,3146608953&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto.webp"
                alt=""
              />蒸压加气混凝土砂加气 B03级
            </div>
            <div>
              <img
                src="../assets/images/u=2741025976,2928865828&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto.webp"
                alt=""
              />蒸压加气混凝土 B03级
            </div>
            <div>
              <img
                src="../assets/images/u=36529644,1190754266&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto.webp"
                alt=""
              />蒸压加气混凝土 B03级
            </div>
            <div>
              <img
                src="../assets/images/u=804385356,2988014069&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto.webp"
                alt=""
              />蒸压加气混凝土 B03级
            </div>
          </el-tab-pane>
        </el-tabs>
      </section>
      <section class="intr et-slide" id="tab-intr">
        <b>产品介绍</b>
        <div class="intr_content">
          <div class="left">
            <div class="title">6大产品服务线</div>
            <ul>
              <el-tabs
                v-model="activeName2"
                tab-position="left"
                @tab-click="handleClick"
              >
                <el-tab-pane label="地图找商家" name="a">
                  <div class="right">
                    <div>
                      <img src="../assets/images/首页.png" alt="" />首页
                    </div>
                    <div>
                      <img
                        src="../assets/images/首页备份.png"
                        alt=""
                      />地图找商家
                    </div>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="采购商城+银行支付" name="c">
                  <div class="right">
                    <div>
                      <img src="../assets/images/首页.png" alt="" />首页
                    </div>
                    <div>
                      <img src="../assets/images/商城@2x.png" alt="" />商城
                    </div>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="企业招聘" name="d">
                  <div class="right">
                    <div>
                      <img src="../assets/images/首页.png" alt="" />首页
                    </div>
                    <div>
                      <img src="../assets/images/招聘@2x.png" alt="" />招聘
                    </div>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="工艺师服务" name="e">
                  <div class="right">
                    <div>
                      <img src="../assets/images/首页.png" alt="" />首页
                    </div>
                    <div>
                      <img src="../assets/images/工艺师@2x.png" alt="" />工艺师
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </ul>
          </div>
        </div>
      </section>
      <section class="cooperation et-slide" id="tab-cooperation">
        <b>合作品牌商</b>
        <div class="find">
          <div>
            <img src="../assets/images/gc.jpg" alt="" />
            <span>全国超过1800+加气砖厂合作</span>
          </div>
          <div>
            <img src="../assets/images/jqz.jpg" alt="" />
            <span>全国超过1000+原料设备供应</span>
          </div>
        </div>
      </section>
      <section class="work et-slide" id="tab-work">
        <b>合作工艺师+化验室服务</b>
        <p>500+具有丰富加气砖研发化验室的工艺师为你保驾护航</p>
        <img src="../assets/images/编组 8@3x.png" alt="" />
      </section>
      <section class="supplier et-slide" id="tab-supplier">
        <b>供应商入驻</b>
        <p>加气App企业认证申请入驻</p>
        <div class="icon">
          <div>
            <img src="../assets/images/加气砖厂商@3x.png" alt="" /><span
              >加气砖工厂</span
            >
          </div>
          <div>
            <img src="../assets/images/设备商@3x.png" alt="" /><span
              >设备商</span
            >
          </div>
          <div>
            <img src="../assets/images/原料商@3x.png" alt="" /><span
              >原料商</span
            >
          </div>
          <div>
            <img src="../assets/images/耗材@3x.png" alt="" /><span>耗材商</span>
          </div>
          <div>
            <img src="../assets/images/经销商@3x.png" alt="" /><span
              >经销商</span
            >
          </div>
          <div>
            <img src="../assets/images/批发商@3x.png" alt="" /><span
              >批发商</span
            >
          </div>
          <div>
            <img src="../assets/images/工艺师@3x.png" alt="" /><span
              >工艺师</span
            >
          </div>
          <div>
            <img src="../assets/images/技术服务商@3x.png" alt="" /><span
              >技术服务商</span
            >
          </div>
        </div>
      </section>
      <section class="about et-slide" id="tab-about">
        <b>关于我们</b>
        <div class="about_con">
          <img src="../assets/images/位图@3x.png" alt="" />
          <div>
            <p>杭州家气人网络科技有限公司</p>
            <span>
              加气人是中国加气混凝土产业互联网服务平台，致力于提供涵盖整个加气混凝土砖（简称加气砖）产业价值链的综合型全产业链优质产品服务， 包括加气砖成品的一站式采购、加气砖工厂的原料、设备和耗材等商品的一站式采购、运输物流仓储服务、以及供应链金融服务、国际电商、加气砖智慧工厂、加气砖产业大数据等产品服务。服务对象既包含整个加气砖产业价值链上的建筑商开发商、加气砖生产厂商、经销商、物流仓储商，也包含生产加气砖用的原料商、设备耗材商和提供技术支持的服务商。<br /><br />
              加气人以助力中国加气混凝土产业转型升级改造赋能为目标，以加气砖产业链企业间互惠互利为原则，依托互联网、物联网、云计算、大数据和人工智能等技术升级改造传统生产和贸易的粗放式发展模式，促进产业链的数字化和数智化转型；加气人搭建的交易平台和建立的数据通道将促使银行等金融机构更安全快捷的连通上游原材料、中游制造业和下游中小经销商企业，整体改变其过去传统低效的生产制造和贸易模式，驱动整个加气砖产业链更加高效营运。
            </span>
          </div>
        </div>
      </section>
    </main>
    <footer>
      <img src="../assets/images/logo1.png" alt="" />
      <p>
        Copyright 02021杭州家气人科技有限公司联系电话：0571-8854-7788
        合作邮箱：15224744@qq.com <br />
        浙ICP备2023000362号-1
      </p>
    </footer>
    <div>
      <el-backtop :right="26"></el-backtop>
    </div>
  </div>
</template>

<script>


export default {
  data() {
    return {
      activeName1: "one",
      activeName2: "a",
      activeName: "first",
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
  mounted(){
    
    let path = window.location.pathname
    if(path && path.length >1){
      let code = path.substring(1)

      location.href = "https://prod.jqcha.jiaqiren.com/mobile/#/MapAccredit?inviteCode=" + code
    }
    
  }
};
</script>
<style scoped lang="less">
@media screen and (min-width: 800px) {
  #app {
    padding: 0;
  }

  header {
    background-color: #fff;
    // border-bottom: 1px solid #ddd;
    position: fixed;
    z-index: 999;
    top: 0;
    height: 80px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

    // ul,
    // a {
    //   list-style-type: none;
    // }
    p {
      // width: 233px;
      // height: 24px;
      // font-size: 14px;
      color: #666666;
      // line-height: 24px;
      padding: 28px 63px;
    }

    .logo img {
      width: 130px;
      height: 48px;
      margin-left: 100%;
    }

    // ul {
    //   font-size: 14px;
    //   display: flex;
    //   flex-wrap: wrap;
    //   margin-right: 70px;

    //   ::v-deep .el-tabs__nav {
    //     display: flex;

    //     .el-tabs__item.is-active {
    //       color: #09ae9c;
    //     }

    //     .el-tabs__active-bar {
    //       background-color: #09ae9c;
    //     }

    //     .el-tabs__item:hover {
    //       color: #09ae9c;
    //     }
    //   }
    // }
    a {
      text-decoration: none;
    }

    .et-hero-tabs,
    .et-slide {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100vh;
      position: relative;
      background: #eee;
      text-align: center;
      padding: 0 2em;
    }
    .et-hero-tabs-container {
      display: flex;
      flex-direction: row;
      // position: absolute;
      // bottom: 0;
      // width: 100%;
      // height: 70px;
      // box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
      // background: #fff;
      // z-index: 10;
      height: 100%;
      font-size: 14px;
      display: flex;
      flex-wrap: wrap;
      margin-right: 70px;
    }
    .et-hero-tabs-container--top {
      position: fixed;
      top: 0;
    }

    .et-hero-tab {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      width: 90px;
      color: #000;
      letter-spacing: 0.1rem;
      transition: all 0.5s ease;
      font-size: 0.8rem;
    }
    // .et-hero-tab:hover {
    //   color: white;
    //   background: rgba(102, 177, 241, 0.8);
    //   transition: all 0.5s ease;
    // }

    .et-hero-tab-slider {
      position: absolute;
      top: 0;
      width: 0;
      height: 6px;
      background: #09ae9c;
      transition: left 0.3s ease;
    }
  }

  .bnaner img {
    margin-top: 80px;
    width: 100%;
  }

  main {
    text-align: center;

    .title {
      font-weight: 600;
      margin: 40px auto 10px;
    }

    .side {
      ::v-deep .el-tabs__nav {
        float: none;

        .el-tabs__active-bar {
          left: 44.5%;
        }

        .el-tabs__item.is-active {
          color: #09ae9c;
        }

        .el-tabs__active-bar {
          background-color: #09ae9c;
        }

        .el-tabs__item:hover {
          color: #09ae9c;
        }
      }

      .el-tab-pane {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding: 0 10%;
        text-align: center;
        font-size: 16px;
        color: #333333;

        div {
          width: 230px;
          margin-left: 50px;

          img {
            width: 230px;
            height: 230px;
            border-radius: 5px;
          }
        }
      }
    }
  }

  .intr {
    // background-color: #f4fbfa;
    /* 加载背景图 */
    background-image: url(../assets/images/intrBg.png);
    /* 背景图垂直、水平均居中 */
    background-position: center center;
    /* 背景图不平铺 */
    background-repeat: no-repeat;
    /* 让背景图基于容器大小伸缩 */
    background-size: cover;
    margin: 30px auto;
    padding: 20px 0 45px 0;

    b {
      padding: 30px;
      display: block;
    }

    .intr_content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      .title {
        text-align: left;
      }

      ::v-deep .el-tabs__nav {
        float: none;

        .el-tabs__item.is-active {
          color: #09ae9c;
        }

        .el-tabs__active-bar {
          background-color: #09ae9c;
        }

        .el-tabs__item:hover {
          color: #09ae9c;
        }
      }

      .left {
        ul > li {
          text-align: left;
          padding-top: 15px;
        }

        ::marker {
          color: #21b3a2;
        }
      }

      .right {
        display: flex;
        flex-wrap: wrap;
        margin-left: 150px;

        div {
          width: 259px;
          font-size: 14px;

          img {
            width: 259px;
            height: 486px;
          }
        }

        div:nth-child(1) {
          margin-top: 40px;
        }
      }
    }
  }

  .cooperation {
    .find {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: 40px;
      font-size: 14px;
      color: #fff;

      div {
        width: 240px;
        margin-left: 20px;

        img {
          width: 240px;
          height: 150px;
          border-radius: 5px;
        }

        span {
          position: relative;
          top: -30px;
          display: none;
        }
      }

      div:hover {
        background: url("../assets/images/渐变@3x.png") no-repeat;
        // background-size: cover;
        z-index: 9999;

        span {
          display: block;
        }
      }
    }
  }

  .work {
    background-color: #f4fbfa;
    margin: 30px auto;
    text-align: center;

    b {
      display: block;
      padding-top: 36px;
    }

    p {
      padding-top: 16px;
      font-size: 14px;
      color: #808382;
    }

    img {
      width: 210px;
    }
  }

  .supplier {
    margin: 30px auto;
    text-align: center;

    b {
      display: block;
      padding-top: 36px;
    }

    p {
      padding-top: 16px;
      font-size: 14px;
    }

    .icon {
      font-size: 12px;
      display: flex;
      padding: 30px 15%;
      justify-content: left;
      flex-wrap: wrap;

      div {
        margin-top: 30px;

        span {
          display: block;
          width: 200px;
        }

        img {
          width: 70px;
          height: 80px;
        }
      }
    }
  }

  .about {
    background-color: #f4fbfa;

    b {
      display: block;
      padding-top: 80px;
    }
    .about_con {
      // background-color: #f4fbfa;
      display: flex;
      padding: 50px 210px 80px;

      div {
        flex: 2.5;
        padding: 40px 30px 40px 80px;
        background-color: #fff;
        text-align: left;

        p {
          text-align: left;
          padding: 0 0 20px 0;
          font-size: 16px;
          font-weight: 600;
        }

        span {
          font-size: 14px;
        }
      }
    }

    img {
      flex: 1;
      width: 340px;
      height: 300px;
      position: relative;
      top: 50px;
      left: 50px;
    }
  }

  footer {
    text-align: center;
    font-size: 12px;

    img {
      width: 160px;
    }

    p {
      border-top: 1px solid #eee;
      margin: 15px 0 0 0;
      padding: 10px 0;
    }
  }
}

//媒体查询
@media screen and (max-width: 800px) {
  #app {
    padding: 0;
  }

  header {
    background-color: #fff;
    position: fixed;
    z-index: 999;
    top: 0;
    height: 80px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    // ul,
    // a {
    //   list-style-type: none;
    // }

    .logo img {
      width: 100px;
      height: 25px;
      margin-top: 10px;
      margin-left: 110%;
    }

    p {
      display: none;
    }

    // ul {
    //   font-size: 14px;
    //   display: flex;
    //   flex-wrap: wrap;

    //   ::v-deep .el-tabs__nav {
    //     display: flex;

    //     .el-tabs__item.is-active {
    //       color: #09ae9c;
    //     }

    //     .el-tabs__active-bar {
    //       background-color: #09ae9c;
    //     }

    //     .el-tabs__item:hover {
    //       color: #09ae9c;
    //     }
    //   }
    // }
    a {
      text-decoration: none;
    }

    .et-hero-tabs,
    .et-slide {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100vh;
      position: relative;
      background: #eee;
      text-align: center;
      padding: 0 2em;
    }
    .et-hero-tabs-container {
      display: flex;
      flex-direction: row;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 70px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
      background: #fff;
      z-index: 10;
    }
    .et-hero-tabs-container--top {
      position: fixed;
      top: 0;
    }

    .et-hero-tab {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      color: #000;
      letter-spacing: 0.1rem;
      transition: all 0.5s ease;
      font-size: 0.8rem;
    }
    .et-hero-tab:hover {
      color: white;
      background: rgba(102, 177, 241, 0.8);
      transition: all 0.5s ease;
    }

    .et-hero-tab-slider {
      position: absolute;
      bottom: 0;
      width: 0;
      height: 6px;
      background: #66b1f1;
      transition: left 0.3s ease;
    }
  }

  .bnaner {
    margin-top: 30px;

    img {
      margin-top: 50px;
      width: 100%;
    }
  }

  main {
    text-align: center;

    .title {
      font-weight: 600;
      margin: 40px auto 10px;
    }

    .side {
      ::v-deep .el-tabs__nav {
        float: none;

        .el-tabs__active-bar {
          left: 20%;
        }

        .el-tabs__item.is-active {
          color: #09ae9c;
        }

        .el-tabs__active-bar {
          background-color: #09ae9c;
        }

        .el-tabs__item:hover {
          color: #09ae9c;
        }
      }

      .el-tab-pane {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding: 0 10%;
        font-size: 12px;
        text-align: center;

        div {
          width: 160px;
          margin-left: 50px;

          img {
            width: 160px;
            height: 150px;
            border-radius: 5px;
          }
        }
      }
    }
  }

  .intr {
    background-color: #f4fbfa;
    margin: 30px auto;
    padding: 20px 0 45px 0;

    b {
      padding: 30px;
      display: block;
    }

    .intr_content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      .title {
        text-align: left;
      }

      ::v-deep .el-tabs__nav {
        float: none;

        .el-tabs__item.is-active {
          color: #09ae9c;
        }

        .el-tabs__active-bar {
          background-color: #09ae9c;
        }

        .el-tabs__item:hover {
          color: #09ae9c;
        }
      }

      .left {
        ul > li {
          text-align: left;
          padding-top: 15px;
        }

        ::marker {
          color: #21b3a2;
        }
      }

      .right {
        display: flex;
        flex-wrap: wrap;

        // margin-left: 150px;
        div {
          width: 180px;
          font-size: 14px;

          img {
            width: 180px;
            height: 280px;
          }
        }

        div:nth-child(1) {
          margin-top: 40px;
        }
      }
    }
  }

  .cooperation {
    .find {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
      text-align: center;
      margin: 40px;
      font-size: 14px;
      color: #fff;

      div {
        width: 240px;
        margin-left: 20px;

        img {
          width: 240px;
          height: 150px;
          border-radius: 5px;
        }

        span {
          position: relative;
          top: -30px;
          display: none;
        }
      }

      div:hover {
        background: url("../assets/images/渐变@3x.png") no-repeat;
        // background-size: cover;
        z-index: 9999;

        span {
          display: block;
        }
      }
    }
  }

  .work {
    background-color: #f4fbfa;
    margin: 30px auto;
    text-align: center;

    b {
      display: block;
      padding-top: 36px;
    }

    p {
      padding-top: 16px;
      font-size: 14px;
      color: #808382;
    }

    img {
      width: 210px;
    }
  }

  .supplier {
    margin: 30px auto;
    text-align: center;

    b {
      display: block;
      padding-top: 36px;
    }

    p {
      padding-top: 16px;
      font-size: 14px;
    }

    .icon {
      font-size: 12px;
      display: flex;
      // padding: 30px;
      justify-content: left;
      padding-left: 30px;
      flex-wrap: wrap;

      div {
        margin-top: 30px;

        span {
          display: block;
        }

        img {
          width: 70px;
          height: 80px;
        }
      }
    }
  }

  .about {
    background-color: #f4fbfa;
    b {
      display: block;
      padding: 20px;
    }
    .about_con {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      // padding: 30px 150px;
      div {
        padding: 30px;
        background-color: #fff;
        text-align: left;

        p {
          text-align: left;
          padding: 0 0 20px 0;
          font-size: 16px;
          font-weight: 600;
        }

        span {
          font-size: 14px;
        }
      }
    }

    img {
      width: 200px;
      height: 220px;
    }
  }

  footer {
    text-align: center;
    font-size: 12px;

    img {
      width: 160px;
    }

    p {
      border-top: 1px solid #eee;
      margin: 15px 0 0 0;
      padding: 10px 0;
    }
  }
}
</style>
